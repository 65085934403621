<template>
  <div class="page">
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="top_box">
            <div class="img_box">
              <img src="./img/activity_icon.png" alt="" />
            </div>
            <div class="name">{{ item.activityName }}</div>
            <div class="time">{{ item.intime }}</div>
          </div>
          <div class="center_box">
            <div class="img_box">
              <img :src="item.activityPicture" alt="" />
            </div>
            <p>{{ item.content }}</p>
          </div>
          <div class="bottom_box">
            <p>活动时间：{{ item.startDate }}-{{ item.endDate }}</p>
            <p>
              活动人数：<span>{{ item.applyUserCount }}</span
              >/{{ item.limitCount == 0 ? "-" : item.limitCount }}
            </p>
            <p>活动地点：{{ item.activityAddress }}</p>
          </div>
          <div
            class="btn_box orange"
            v-if="item.isJoin == '2'"
            @click.stop="joinOrNot('确定参与', 1, item)"
          >
            参与
          </div>
          <div
            class="btn_box withe"
            v-else-if="item.isJoin == '1'"
            @click.stop="joinOrNot('取消参与', 2, item)"
          >
            取消参与
          </div>
          <div class="btn_box empty" v-else-if="item.isJoin == '3'">已结束</div>
          <div class="btn_box empty" v-else-if="item.isJoin == '4'">已满员</div>
          <div class="btn_box empty" v-else-if="item.isJoin == '5'">进行中</div>
        </div>
      </v-list>
    </div>
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="join_message">是否{{ joinStr }}该活动？</p>
    </v-dialog>
    <div v-if="isOwnerStatus == 1" class="add" @click="addActivities()">
      <img src="./img/add_activity.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getAssActivityListUrl,
  checkIsOwnerUrl,
  joinOrQuitUrl,
} from "./api.js";
export default {
  name: "associationActivityList",
  data() {
    return {
      isDialog: false,
      joinStr: "",
      isOwnerStatus: null,
      id: null,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      joinFlag: null,
      nowId: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.checkIsOwnerFun();
  },
  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        associationId: this.id,
      };
      this.$axios
        .get(`${getAssActivityListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    addActivities() {
      this.$router.push({
        name: "publicationActivity",
        query: {
          id: this.id,
        },
      });
    },
    joinOrNot(str, flag, data) {
      this.nowId = data.id;
      this.joinFlag = flag;
      this.joinStr = str;
      this.isDialog = true;
    },
    confirm() {
      let params = {
        userId: this.userId,
        id: this.nowId,
        joinFlag: this.joinFlag,
      };
      this.$axios
        .post(`${joinOrQuitUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$toast(res.msg);
            this.init();
          }
        });
      this.isDialog = false;
    },
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.$refs.list._data.loading = true;
      this.finished = false;
      this.getList();
    },
    toDetail(data) {
      this.$router.push({
        name: "communityActivitiesDetail",
        query: {
          id: data.id,
        },
      });
    },
    checkIsOwnerFun() {
      //判断当前用户是否为当前社群群主
      let params = {
        userId: this.userId,
        associationId: this.id,
      };
      this.$axios
        .get(`${checkIsOwnerUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.isOwnerStatus = res.data;
            // this.isOwnerStatus = 0
          }
        });
    },
  },
};
</script>

<style lang="less">
.page {
  width: 100%;
  min-height: 100vh;

  .add {
    width: 184px;
    position: fixed;
    right: 0;
    bottom: 168px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .join_message {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 86px 124px 58px;
    white-space: nowrap;
  }

  .list {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    padding-bottom: 96px;

    .item {
      width: 100%;
      margin-top: 28px;
      height: 440px;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 18px;

      .btn_box {
        width: 194px;
        height: 62px;
        text-align: center;
        line-height: 62px;
        font-size: 26px;
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px 0px 16px 0px;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .orange {
        color: #fff;
        background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
      }

      .withe {
        border: 2px solid;
        color: #fe9919;
      }

      .empty {
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        color: #a8a8a8;
      }

      .bottom_box {
        width: 100%;
        padding: 0 28px;
        box-sizing: border-box;
        margin-top: 32px;

        p {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 4px;

          span {
            color: #fe9615;
          }
        }
        p:nth-last-child(1) {
          width: 450px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .center_box {
        width: 100%;
        height: 174px;
        padding: 26px 28px 0 28px;
        box-sizing: border-box;
        display: flex;

        .img_box {
          margin-right: 20px;
          width: 246px;
          height: 100%;
          border-radius: 14px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          flex: 1;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 37px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }

      .top_box {
        width: 100%;
        height: 84px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        position: relative;

        .img_box {
          width: 40px;
          height: 40px;
          background: #feedd4;
          border-radius: 14px;
          position: absolute;
          left: 28px;
          top: 50%;
          transform: translateY(-50%);
          img {
            width: 100%;
            height: 100%;
          }
        }

        .name {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
          position: absolute;
          left: 82px;
          top: 50%;
          transform: translateY(-50%);
        }

        .time {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
